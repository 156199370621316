@import "./variables.scss";
.Cabeza{
    background-color: darken($color: $color1, $amount: 0);;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    height: $altoCabezayPies;
    display: grid;
    grid-template-columns: $altoCabezayPies 1fr $altoCabezayPies;
    color: white;
    z-index: 100;
    .BarraHerramientas{
        display: grid;
        grid-template-columns: auto 1fr auto;
        .separador{
            min-width: 20px;
        }
        .Tools{
            overflow-x: auto;
            display: flex;
            font-size: 22px;
            .ToolsSel{
                box-sizing: border-box;
                border-right: 2px solid white;
                display: flex;
            }
            .ToolsSelAction{
                box-sizing: border-box;
                display: flex;
            }
            .BtnTool{
                width: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                box-sizing: border-box;
                &:active{
                    background-color: darken( $color1, 10% );
                }
                &.activo{
                    border-bottom: 3px solid $color2;
                }
            }
            .Descktop{
                display: none;
                @media #{$DesktopLandscape}{
                    display: flex;
                }
            }
        }
    }
}