#Dialog{
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: #00000080;
    visibility: hidden;
    z-index: 0;
    transition: 250ms;
    display: flex;
    justify-content: center;
    align-items: center;
    &.activo{
        z-index: 300;
        visibility: visible;
    }
    .dialogBox{
        position: relative;
        background-color: white;
        width: 100%;
        height: 100%;
        max-width: 400px;
        max-height: 500px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .MtLogo{
            width: 190px;
            height: 200px;
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .DataMt{
            height: 200px;
            box-sizing: border-box;
            padding-top: 10px;
            font-size: 30px;
            color: #383838;
            font-family: inherit;
        }
    }
}