@import "../variables.scss";
$color1:#209125;
$color2:#c62828;
#DownloadButton{
  border-radius: 10px;
  width: 250px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
  user-select: none;
  margin-bottom: 10px;
  .animacion{
    margin-left: 5px;
    position: relative;
    width: 35px;
      height: 35px;
    .spinner{
      position: absolute;
      width: 100%;
      height: 100%;
      border: 2px #b0bec5 solid;
      border-radius: 50%;
      box-sizing: border-box;
      border-top-color: $color1;
      animation: Rotar 1.1s linear infinite;
    }
    .Valor{
      $FS:12px;
      position: absolute;
      height: 100%;
      font-size: $FS;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &::after{
        content: "%";
      }
    }
  }
}
.NoDescargado{
  border: solid 2px $color1;
  color: $color1;
  box-sizing: border-box;
  box-shadow: $sombra1;
  &:active{
    $colorClick:darken($color1,15%);
    border: solid 2px $colorClick;
    color: $colorClick;
    box-shadow: none;
  }
}
.Descargando{
  border: solid 2px $color1;
  color: $color1;
  box-sizing: border-box;
  background-color: darken($color: white, $amount: 2)
}
.descargado{
  border: solid 2px $color2;
  color: $color2;
  box-sizing: border-box;
  background-color: darken($color: white, $amount: 2)
}

@keyframes Rotar{
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}