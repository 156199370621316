@import "./icomoon/style.scss";
#Visor{
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    *{
        user-select: none;
    }
}