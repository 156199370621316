@import "./variables.scss";
.Pies{
    background-color: darken($color: $color2, $amount: 10);;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: $altoCabezayPies;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(60px,1fr));
    color: white;
    z-index: 100;
    font-size: 20px;
    @media #{$DesktopLandscape}{
        display: none;
    }
    .BtnPies{
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 100ms;
        box-sizing: border-box;
        &:active{
            background-color: #002c3f;
        }
        &.activo{
            border-top: 2px solid $color1;
        }
    }
}